import { colorVariables } from "./global"
// Elements variables
const actionVariables = {
  styles: {
    dark: {
      text_color: colorVariables.gray_darken,
      text_color_hover: colorVariables.black,
      button_background: colorVariables.gray_darken,
      button_color: colorVariables.white,
      button_background_hover: colorVariables.black,
    },
    light: {
      text_color: colorVariables.gray_lighten,
      text_color_hover: colorVariables.white,
      button_background: colorVariables.gray_lighten,
      button_color: colorVariables.gray_darken,
      button_background_hover: colorVariables.gray_light,
    },
    primary: {
      text_color: colorVariables.primary,
      text_color_hover: colorVariables.primary_dark,
      button_background: colorVariables.primary_dark,
      button_color: colorVariables.primary_lighten,
      button_background_hover: colorVariables.primary_light,
    },
    secondary: {
      text_color: colorVariables.secondary,
      text_color_hover: colorVariables.secondary_dark,
      button_background: colorVariables.secondary_dark,
      button_color: colorVariables.secondary_lighten,
      button_background_hover: colorVariables.secondary_light,
    },
    default: {
      text_color: colorVariables.gray_middle,
      text_color_hover: colorVariables.gray_middle_dark,
      button_background: colorVariables.gray_middle,
      button_color: colorVariables.gray_darken,
      button_background_hover: colorVariables.gray_middle_dark,
    },
  },
  sizes: {
    small: {
      text_gap: "1rem",
      text_font_size: "1rem",
      text_font_weight: "500",
      button_padding: ".25rem .375rem",
      button_radius: ".075rem",
      button_gap: ".125rem",
      button_font_size: "1rem",
      button_font_weight: "700",
    },
    default: {
      text_gap: ".25rem",
      text_font_size: "1.125rem",
      text_font_weight: "700",
      button_padding: ".375rem .5rem",
      button_radius: ".125rem",
      button_gap: ".25rem",
      button_font_size: "1.125rem",
      button_font_weight: "700",
    },
    medium: {
      text_gap: ".375rem",
      text_font_size: "1.25rem",
      text_font_weight: "700",
      button_padding: ".5rem .75rem",
      button_radius: ".25rem",
      button_gap: ".375rem",
      button_font_size: "1.25rem",
      button_font_weight: "700",
    },
    large: {
      text_gap: ".5rem",
      text_font_size: "1.5rem",
      text_font_weight: "700",
      button_padding: ".75rem 1rem",
      button_radius: ".375rem",
      button_gap: ".5rem",
      button_font_size: "1.375rem",
      button_font_weight: "700",
    },
  },
}
const iconVariables = {
  font_family: "icon-basic-set",
  sizes: {
    small: "1rem",
    default: "1.125rem",
    medium: "1.25rem",
    large: "1.5rem",
  },
  styles: {
    primary: colorVariables.primary,
    secondary: colorVariables.secondary,
    dark: colorVariables.gray_darken,
    light: colorVariables.gray_lighten,
    default: "inherit",
  },
  names: {
    accordion_fold: '"\\e900"',
    accordion_unfold: '"\\e901"',
    action_add: '"\\e902"',
    action_circle_add: '"\\e903"',
    action_circle_clear: '"\\e904"',
    action_circle_filled_add: '"\\e905"',
    action_circle_filled_clear: '"\\e906"',
    action_circle_filled_info: '"\\e907"',
    action_circle_filled_remove: '"\\e908"',
    action_circle_filled_settings: '"\\e909"',
    action_circle_remove: '"\\e90a"',
    action_clear: '"\\e90b"',
    action_delete: '"\\e90c"',
    action_info: '"\\e90d"',
    action_remove: '"\\e90e"',
    alarm_add: '"\\e90f"',
    alarm_default: '"\\e910"',
    alarm_off: '"\\e911"',
    alarm_on: '"\\e912"',
    alert_circle_empty: '"\\e913"',
    alert_circle_full: '"\\e914"',
    alert_explosion: '"\\e915"',
    alert_hexagonal: '"\\e916"',
    alert_triangle: '"\\e917"',
    arrow_down: '"\\e918"',
    arrow_left: '"\\e919"',
    arrow_right: '"\\e91a"',
    arrow_up: '"\\e91b"',
    calendar_date: '"\\e91c"',
    calendar_default: '"\\e91d"',
    checkbox_checked: '"\\e91e"',
    checkbox_null: '"\\e91f"',
    checkbox_unchecked: '"\\e920"',
    dart_down: '"\\e921"',
    dart_left: '"\\e922"',
    dart_right: '"\\e923"',
    dart_up: '"\\e924"',
    display_float: '"\\e925"',
    display_full: '"\\e926"',
    interact_close: '"\\e927"',
    interact_delete: '"\\e928"',
    interact_done_all: '"\\e929"',
    interact_done: '"\\e92a"',
    interact_update: '"\\e92b"',
    map_location: '"\\e92c"',
    menu_hamburger: '"\\e92d"',
    menu_points_vertical: '"\\e92e"',
    menu_setting: '"\\e92f"',
    menu_points_horizontal: '"\\e930"',
    notification_disabled: '"\\e931"',
    notification_none: '"\\e932"',
    notification_notify: '"\\e933"',
    notification_ring: '"\\e934"',
    operation_download: '"\\e935"',
    operation_upload: '"\\e936"',
    organize_grid: '"\\e937"',
    organize_list: '"\\e938"',
    radio_checked: '"\\e939"',
    radio_unchecked: '"\\e93a"',
    star_empty: '"\\e93b"',
    star_full: '"\\e93c"',
    star_half: '"\\e93d"',
    triangle_down: '"\\e93e"',
    triangle_up: '"\\e93f"',
    user_add_group: '"\\e940"',
    user_add: '"\\e941"',
    user_default: '"\\e942"',
    user_group: '"\\e943"',
    visibility_hidden: '"\\e944"',
    visibility_visible: '"\\e945"',
  },
}

export { actionVariables, iconVariables }
