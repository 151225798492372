import React from "react"
import PropTypes from "prop-types"
import SbEditable from "storyblok-react"
import DynamicComponent from "../../utils/dynamicComponent"
import { displaierSchema } from "../../utils/componentSchemas"
import { getWidth } from "../../utils/styleHelpers"
import { createUseStyles, JssProvider, createGenerateId } from "react-jss"

const generateId = createGenerateId()

function Displaier(props) {
  const classes = DisplaierStyles({ ...props.blok })
  const content =
    props.blok.body &&
    props.blok.body.map(childBlok => {
      childBlok.length = props.blok.body.length
      childBlok.parentGap = props.blok.gap
      return <DynamicComponent blok={childBlok} key={childBlok._uid} />
    })
  return (
    <SbEditable content={props.blok} key={props.blok._uid}>
      <JssProvider generateId={generateId}>
        <div className={classes.displaier}>{content || props.children}</div>
      </JssProvider>
    </SbEditable>
  )
}

Displaier.defaultProps = {
  blok: {
    type: "row",
    align: "start",
    justify: "start",
    gap: "medium",
    spacing: "none",
    isContainerChild: false,
    fillContainer: true,
  },
}

Displaier.prototype = {
  blok: PropTypes.shape({
    type: PropTypes.oneOf(displaierSchema.types),
    align: PropTypes.oneOf(displaierSchema.alignments),
    justify: PropTypes.oneOf(displaierSchema.justifies),
    gap: PropTypes.oneOf(displaierSchema.gaps),
    spacing: PropTypes.oneOf(displaierSchema.spacings),
    size: PropTypes.oneOf(displaierSchema.sizes),
    isContainerChild: PropTypes.bool,
    fillContainer: PropTypes.bool,
  }),
}

const DisplaierStyles = createUseStyles(
  ({ column, displaier, mediaQuery }) => ({
    displaier: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: ({ type }) => type,
      alignItems: ({ type, align, justify }) =>
        type === "row" ? align : justify,
      justifyContent: ({ type, align, justify }) =>
        type === "row" ? justify : align,
      rowGap: ({ gap }) => (gap === "none" ? "0" : column.gaps[gap]),
      columnGap: ({ gap }) => (gap === "none" ? "0" : column.gaps[gap] ),
      padding: ({ spacing }) =>
        `${spacing === "none" ? "0" : displaier.spacings[spacing]} 0`,
      minHeight: ({ fillContainer }) =>
        !!fillContainer ? "inherit" : "auto",
      // As a child of a Displayer
      flex: ({ size, isContainerChild }) =>
        isContainerChild || (isContainerChild && size === 0) ? "1 1 0" : "none" ,
      width: ({ size, parentGap, length, isContainerChild }) =>
        isContainerChild || (isContainerChild && size > 0)
          ? "auto"
          : getWidth(column.gaps[parentGap] || "0", length, size),
    },
    [mediaQuery.only.mobile]: {
      displaier: {
        flexDirection: [["column"], "!important"],
        width: "100%",
      },
    },
  })
)

export default Displaier
