export function getWidth(gap, contents, size) {
  const contentsLength = contents > 0 ? contents - 1 : 0
  const gapsWidth =
    !!gap && gap !== "0" ? `- (${gap} * ${contentsLength})` : ""
  const width = `calc((100% ${gapsWidth} ) * (${size} / 12))`
  return width
}

const positions = {
  top: "start",
  center: "center",
  bottom: "end",
  left: "start",
  right: "end",
}

export function getFlexPosition(keys, prop) {
  if (keys === "center") {
    return keys
  } else {
    let key = keys.split(" ")[prop === "align" ? 0 : 1]
    return positions[key]
  }
}
