import React, { useState } from "react"
import PropTypes from "prop-types"
import DynamicComponent from "../../utils/dynamicComponent"
import Container from "./Container"
import Icon from "../element/Icon"
import { headerSchema } from "../../utils/componentSchemas"
import { createUseStyles } from "react-jss"

function Header(props) {
  const classes = HeaderStyles({ ...props.blok })
  const [showMenu, setShowMenu] = useState(false)
  const actions =
    props.blok.actions &&
    props.blok.actions.map(childBlok => {
      return <DynamicComponent blok={childBlok} key={childBlok._uid} />
    })
  const links =
    props.blok.links &&
    props.blok.links.map(childBlok => {
      childBlok.size = "small"
      return <DynamicComponent blok={childBlok} key={childBlok._uid} />
    })
  return (
    <header className={classes.header}>
      <Container responsive={"true"} parentClasses={classes.headerContainer}>
        <a className={classes.headerLogo} href="#">
          <img src={props.blok.logo.filename} alt={props.blok.logo.alt} />
        </a>
        <div className={`${classes.headerMenu} ${showMenu ? "active" : ""}`}>
          <nav className={classes.headerNav}>{links}</nav>
          <nav className={classes.headerNav}>{actions}</nav>
        </div>
        <button
          style={{ cursor: "pointer", padding: "0" }}
          className={classes.headerToggleMenu}
          onClick={() => setShowMenu(!showMenu)}
        >
          <Icon
            name={showMenu ? "interact_close" : "menu_hamburger"}
            color="white"
            size="large"
          />
        </button>
      </Container>
    </header>
  )
}

Header.prototype = {
  blok: PropTypes.shape({
    style: PropTypes.oneOf(headerSchema.styles),
    logo: PropTypes.shape({
      filename: PropTypes.string,
      alt: PropTypes.string,
    }),
    links: PropTypes.array,
    actions: PropTypes.array,
  }),
}

const HeaderStyles = createUseStyles(({ mediaQuery, index, header, menu }) => ({
  header: ({ style }) => ({
    backgroundColor: header.styles[style].background,
    color: menu.styles[style].color,
    position: "sticky",
    top: "0",
    display: "flex",
    alignItems: "center",
    zIndex: index.header,
    height: header.heights.small,
    [mediaQuery.from.tablet]: {
      height: header.heights.medium,
    },
    [mediaQuery.from.desktop]: {
      height: header.heights.large,
    },
  }),

  headerContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    columnGap: "1rem",
    justifyContent: "space-between",
    backgroundColor: "inherit",
  },
  headerLogo: {
    maxHeight: "5rem",
    "& img": {
      height: "100%",
      width: "auto",
    },
    height: `calc(${header.heights.small} - 1rem)`,

    [mediaQuery.from.tablet]: {
      height: `calc(${header.heights.medium} - 1rem)`,
    },
    [mediaQuery.from.desktop]: {
      height: `calc(${header.heights.large} - 1rem)`,
    },
  },
  headerMenu: {
    display: "flex",
    flex: "1 1 0px",
    backgroundColor: "inherit",
    justifyContent: "space-between",
    alignItems: "center",

    [mediaQuery.until[header.collapse_menu]]: {
      display: "none",
    },

    "&.active": {
      [mediaQuery.only.mobile]: {
        top: header.heights.small,
        padding: "1rem",
        width: `calc(100% - 2rem)`,
      },
      [mediaQuery.from.tablet]: {
        top: header.heights.medium,
        width: `calc(100% - 4rem)`,
        padding: "1rem 2rem",
      },
      [mediaQuery.from.desktop]: {
        top: header.heights.large,
        padding: "0",
        width: "auto",
      },
      [mediaQuery.until[header.collapse_menu]]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        rowGap: ".75rem",
        position: "absolute",
        left: "0",
        right: "0",
      },
    },
  },
  headerNav: {
    display: "flex",
    columnGap: "1rem",
    "& a": {
      color: "inherit",
      opacity: ".8",
      "&:hover": {
        color: "inherit",
        opacity: "1",
      },
    },
    "& button": {
      color: "inherit",
      opacity: ".8",
      "&:hover": {
        color: "inherit",
        opacity: "1",
      },
    },
    [mediaQuery.until[header.collapse_menu]]: {
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "start",
      rowGap: ".75rem",
    },
  },
  headerToggleMenu: {
    display: "none",
    color: "inherit",
    [mediaQuery.until[header.collapse_menu]]: {
      display: "block",
    },
  },
}))

export default Header
