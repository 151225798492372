import React from "react"
import { ThemeProvider } from "react-jss"
import theme from "../styles/theme"
import useStoryblok from "../utils/storyblok"
import Page from "../components/Page"
import Layout from "../components/Layout"

export default function PageIndex({ pageContext, location }) {
  const story = useStoryblok(pageContext.story, location)
  const settings = pageContext.settings
  return (
    <ThemeProvider theme={theme}>
      <Layout location={location} settings={settings}>
        <Page blok={story.content} />
      </Layout>
    </ThemeProvider>
  )
}
