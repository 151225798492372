import React from "react"
import PropTypes from "prop-types"
import StoryblokClient from "storyblok-js-client"
import DynamicComponent from "../../utils/dynamicComponent"
import Container from "./Container"
import { createUseStyles } from "react-jss"
import { footerSchema } from "../../utils/componentSchemas"
import { getWidth } from "../../utils/styleHelpers"

const sbClient = new StoryblokClient({
  accessToken: `${process.env.SB_TOKEN}`,
})

function Footer({ blok }) {
  const classes = FooterStyles({ ...blok })
  const text = sbClient.richTextResolver.render(blok.text)
  const copywrite = sbClient.richTextResolver.render(blok.copywrite)
  const menus =
    blok.menus &&
    blok.menus.map(childBlok => {
      childBlok.length = blok.menus.length
      childBlok.style = blok.style
      childBlok.size = "small"
      return (
        <DynamicComponent
          blok={childBlok}
          key={childBlok._uid}
        />
      )
    })

  return (
    <footer className={classes.footer}>
      <div className={classes.footerNavigator}>
        <Container responsive={"true"} parentClasses={classes.footerContainer}>
          {(blok.logo || blok.text) && (
            <div className={classes.footerHead}>
              <a className={classes.footerLogo} href="#">
                <img src={blok.logo.filename} alt={blok.logo.alt} />
              </a>
              <div
                className={classes.footerText}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          )}
          <div className={classes.footerMenus}>{menus}</div>
        </Container>
      </div>
      <div className={classes.footerCopywrite}>
        <Container responsive={"true"}>
          <div
            className={classes.footerCopywriteText}
            dangerouslySetInnerHTML={{ __html: copywrite }}
          />
        </Container>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  blok: {
    style: "light",
  },
}

Footer.prototype = {
  blok: PropTypes.shape({
    style: PropTypes.oneOf(footerSchema.styles),
    logo: PropTypes.shape({
      filename: PropTypes.string,
      alt: PropTypes.string,
    }),
    menus: PropTypes.array,
    copywrite: PropTypes.object,
  }),
}

const FooterStyles = createUseStyles(({ footer, mediaQuery, typografy }) => ({
  smalltext: {
    fontWeight: "300",
    lineHeight: "normal",
    letterSpacing: typografy.message.letter_spacing,
    marginBottom: typografy.message.margin,
    fontSize: typografy.message.font_size,
  },
  footer: ({ style }) => ({
    color: footer.styles[style].color,
  }),
  footerContainer: {
    display: "flex",
    width: "100%",
    alignItems: "start",
    columnGap: "1rem",
    flexWrap: "wrap",
    justifyContent: "start",
  },
  footerNavigator: ({ style }) => ({
    padding: "1.5rem 0",
    backgroundColor: footer.styles[style].background,
  }),
  footerHead: ({ menus }) => ({
    flex: !!menus.length ? "none" : "1 1 0",
    width: !!menus.length ? getWidth("1rem", menus.length, 3) : null,
  }),
  footerLogo: {
    display: "block",
    height: "5rem",
    marginBottom: "1rem",
    "& img": {
      height: "inherit",
    },
  },
  footerText: {
    color: "inherit",
    extend: "smalltext",
  },
  footerMenus: {
    flex: "1 1 0",
    display: "flex",
    alignItems: "start",
    flexWrap: "wrap",
    fontSize: "1rem",
    fontWeight: "600",
  },
  footerCopywrite: ({ style }) => ({
    padding: "1rem 0",
    backgroundColor: footer.styles[style].background_copywrite,
  }),
  footerCopywriteText: {
    color: "inherit",
    extend: "smalltext",
  },
  [mediaQuery.only.mobile]: {
    footerMenus: {
      flexDirection: "column",
      rowGap: "1rem",
      paddingTop: "1rem",
      "& div": {
        flex: "1 1 0",
        width: "auto",
      },
    },
    footerHead: {
      flex: "1 1 0 !important",
      width: "100% !important",
      textAlign: "center",
    },
    footerContainer: {
      display: "block",
    },
  },
}))

export default Footer
