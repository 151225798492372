import React from "react"
import { createUseStyles } from "react-jss"

function Container(props) {
  const classes = ContainerStyles({ ...props })
  const hasResponsiveClasses =
    props.responsive === "true" ? classes.container : ""
  return (
    <div className={`${hasResponsiveClasses} ${props.parentClasses || ""}`}>
      {props.children}
    </div>
  )
}

const ContainerStyles = createUseStyles(({ container, mediaQuery }) => ({
  container: {
    marginLeft: container.mobile.margin,
    marginRight: container.mobile.margin,
    width: "100%",
    maxWidth: container.mobile.max_width,
    minHeight: "inherit",
    display: "flex",
    alignItems: "center",
    [mediaQuery.from.tablet]: {
      marginLeft: container.tablet.margin,
      marginRight: container.tablet.margin,
      maxWidth: container.tablet.max_width,
      flexWrap: "wrap",
    },
    [mediaQuery.from.laptop]: {
      marginLeft: container.laptop.margin,
      marginRight: container.laptop.margin,
      maxWidth: container.laptop.max_width,
      flexWrap: "wrap",
    },
    [mediaQuery.from.desktop]: {
      marginLeft: container.desktop.margin,
      marginRight: container.desktop.margin,
      maxWidth: container.desktop.max_width,
      flexWrap: "wrap",
    },
    [mediaQuery.from.widescreen]: {
      marginLeft: container.widescreen.margin,
      marginRight: container.widescreen.margin,
      maxWidth: container.widescreen.max_width,
      flexWrap: "wrap",
    },
  },
}))

export default Container
