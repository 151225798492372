import theme from "../styles/theme"

const iconNames = Object.keys(theme.icon.names)
const flexAlignments = ["start", "center", "end", "stretch"]
const flexJustifies = [
  "start",
  "center",
  "end",
  "space-between",
  "space-around",
]
const imageAlignments = [
  "top left",
  "top center",
  "top right",
  "center left",
  "center",
  "center right",
  "bottom left",
  "bottom center",
  "bottom right",
]
const textAlignment = ["left", "center", "right"]
const columnWidths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

// Components schemas
const iconSchema = {
  styles: Object.keys(theme.icon.styles),
  sizes: Object.keys(theme.icon.sizes),
  names: iconNames,
}

const actionSchema = {
  types: ["text", "button"],
  sizes: Object.keys(theme.action.sizes),
  styles: Object.keys(theme.action.styles),
  targets: ["_self", "_blank"],
  icons: iconNames,
}
const displaierSchema = {
  types: ["row", "column", "grid"],
  alignments: flexAlignments,
  justifies: flexJustifies,
  gaps: Object.keys(theme.column.gaps),
  spacings: Object.keys(theme.displaier.spacings),
  sizes: Object.keys(columnWidths),
}
const headingSchema = {
  styles: Object.keys(theme.heading.styles),
  sizes: Object.keys(columnWidths),
  spacings: Object.keys(theme.heading.spacings),
  gaps: Object.keys(theme.column.gaps),
  text_alignment: textAlignment,
}
const mediaSchema = {
  image_alignments: imageAlignments,
  sizes: Object.keys(columnWidths),
  spacings: Object.keys(theme.media.spacings),
}
const headerSchema = {
  styles: Object.keys(theme.header.styles),
}
const footerSchema = {
  styles: Object.keys(theme.footer.styles),
}
const sectionSchema = {
  styles: Object.keys(theme.section.styles),
  heights: Object.keys(theme.section.heights),
  spacings: Object.keys(theme.section.spacings),
  image_alignments: imageAlignments,
  gaps: Object.keys(theme.column.gaps),
}
const menuSchema = {
  styles: Object.keys(theme.menu.styles),
}
export {
  iconSchema,
  actionSchema,
  displaierSchema,
  headingSchema,
  mediaSchema,
  headerSchema,
  footerSchema,
  sectionSchema,
  menuSchema,
}
