import { colorVariables } from "./global"

// Layout variables
const sectionVariables = {
  heights: {
    auto: "auto",
    cover: "100vh",
    large: "75vh",
    medium: "50vh",
    small: "25vh",
  },
  spacings: {
    none: "0",
    small: "1rem",
    default: "1.5rem",
    medium: "2.5rem",
    large: "4rem",
  },
  styles: {
    none: {
      background: "transparent",
      color: colorVariables.gray_darken,
    },
    image: {
      background: null,
      color: colorVariables.gray_darken,
    },
    gradient: {
      background: null,
      color: colorVariables.gray_darken,
    },
    primary: {
      background: colorVariables.primary,
      color: colorVariables.white,
    },
    primary_dark: {
      background: colorVariables.primary_dark,
      color: colorVariables.primary_lighten,
    },
    primary_light: {
      background: colorVariables.primary_light,
      color: colorVariables.primary_darken,
    },
    secondary: {
      background: colorVariables.secondary,
      color: colorVariables.white,
    },
    secondary_dark: {
      background: colorVariables.secondary_dark,
      color: colorVariables.secondary_lighten,
    },
    secondary_light: {
      background: colorVariables.secondary_light,
      color: colorVariables.secondary_darken,
    },
    dark: {
      background: colorVariables.gray_darken,
      color: colorVariables.white,
    },
    light: {
      background: colorVariables.gray_lighten,
      color: colorVariables.gray_darken,
    },
    gray_dark: {
      background: colorVariables.gray_middle_dark,
      color: colorVariables.gray_lighten,
    },
    gray_light: {
      background: colorVariables.gray_middle_light,
      color: colorVariables.gray_darken,
    },
  },
}
const containerVariables = {
  mobile: {
    margin: "1rem",
    max_width: `calc(100% - 2rem)`,
  },
  tablet: {
    margin: "2rem",
    max_width: `calc(100% - 4rem)`,
  },
  laptop: {
    margin: "auto",
    max_width: "996px",
  },
  desktop: {
    margin: "auto",
    max_width: "1240px",
  },
  widescreen: {
    margin: "auto",
    max_width: "1440px",
  },
}
const headerVariables = {
  styles: {
    default: {
      background: colorVariables.primary_darken,
      color: colorVariables.white,
    },
    dark: {
      background: colorVariables.gray_darken,
      color: colorVariables.white,
    },
    light: {
      background: colorVariables.gray_lighten,
      color: colorVariables.gray_darken,
    },
    primary: {
      background: colorVariables.primary_dark,
      color: colorVariables.white,
    },
    secondary: {
      background: colorVariables.secondary_dark,
      color: colorVariables.white,
    },
  },
  heights: {
    small: "3rem",
    medium: "3.5rem",
    large: "4rem",
  },
  collapse_menu: "tablet",
}
const footerVariables = {
  styles: {
    default: {
      background: colorVariables.primary_dark,
      color: colorVariables.white,
      background_copywrite: colorVariables.primary_darken,
    },
    dark: {
      background: colorVariables.gray_dark,
      color: colorVariables.white,
      background_copywrite: colorVariables.gray_darken,
    },
    light: {
      background: colorVariables.gray_lighten,
      color: colorVariables.gray_darken,
      background_copywrite: colorVariables.gray_light,
    },
    primary: {
      background: colorVariables.primary_dark,
      color: colorVariables.white,
      background_copywrite: colorVariables.primary_darken,
    },
    secondary: {
      background: colorVariables.secondary_dark,
      color: colorVariables.white,
      background_copywrite: colorVariables.secondary_darken,
    },
  },
}
const menuVariables = {
  styles: {
    default: {
      background: colorVariables.primary_darken,
      color: colorVariables.white,
      color_hover: colorVariables.gray_light,
    },
    dark: {
      background: colorVariables.gray_darken,
      color: colorVariables.white,
      color_hover: colorVariables.gray_light,
    },
    light: {
      background: colorVariables.gray_lighten,
      color: colorVariables.gray_dark,
      color_hover: colorVariables.gray_darken,
    },
    primary: {
      background: colorVariables.primary_dark,
      color: colorVariables.white,
      color_hover: colorVariables.primary_darken,
    },
    secondary: {
      background: colorVariables.secondary_dark,
      color: colorVariables.white,
      color_hover: colorVariables.secondary_darken,
    },
  },
}

export {
  sectionVariables,
  containerVariables,
  headerVariables,
  footerVariables,
  menuVariables,
}
