import React from "react"
import PropTypes from "prop-types"
import SbEditable from "storyblok-react"
import DynamicComponent from "../../utils/dynamicComponent"
import { sectionSchema } from "../../utils/componentSchemas"
import Container from "./Container"
import { createUseStyles, JssProvider, createGenerateId } from "react-jss"

const generateId = createGenerateId()
function Section(props) {
  const classes = SectionStyles({ ...props.blok })
  const content =
    props.blok.body &&
    props.blok.body.map(childBlok => {
      childBlok.length = props.blok.body.length
      childBlok.parentGap = props.blok.gap
      childBlok.isContainerChild = !!props.blok.container
      return <DynamicComponent blok={childBlok} key={childBlok._uid} />
    })
  return (
    <SbEditable content={props.blok} key={props.blok._uid}>
      <JssProvider generateId={generateId}>
        <section id={props.blok.identifier} className={classes.section}>
          <Container
            responsive={props.blok.container}
            parenClasses={classes.sectionContainer}
          >
            {content}
          </Container>
        </section>
      </JssProvider>
    </SbEditable>
  )
}

Section.defaultProps = {
  blok: {
    id: "Draft",
    container: "true",
    height: "auto",
    spacing: "medium",
    background: "none",
    gap: "default",
    gradinet: null
  },
}

Section.prototype = {
  blok: PropTypes.shape({
    id: PropTypes.string,
    body: PropTypes.array,
    container: PropTypes.oneOf(["true", "false"]),
    height: PropTypes.oneOf(sectionSchema.heights),
    gap: PropTypes.oneOf(sectionSchema.gaps),
    spacing: PropTypes.oneOf(sectionSchema.spacings),
    style: PropTypes.oneOf(sectionSchema.styles),
    image: PropTypes.shape({
      filename: PropTypes.string,
      alt: PropTypes.string,
    }),
    alignment: PropTypes.oneOf(sectionSchema.image_alignments),
    gradient: PropTypes.string,
  }),
}

const SectionStyles = createUseStyles(({ section }) => ({
  section: ({ style, image, spacing, height, alignment, gradient }) => ({
    backgroundColor: section.styles[style].background,
    color: section.styles[style].color,
    backgroundImage:
      style === "image" && !!image.filename ? `url(${image.filename})` : null,
    background: style === "gradient" && !!gradient ? gradient : "",
    backgroundSize: style === "image" ? "cover" : null,
    backgroundRepeat: style === "image" ? "no-repeat" : null,
    backgroundPosition: style === "image" ? alignment : null,
    paddingTop: section.spacings[spacing],
    paddingBottom: section.spacings[spacing],
    minHeight: height === "auto" ? "auto" : section.heights[height],
  }),
}))

export default Section
