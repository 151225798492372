import React from "react"
import Page from "../components/Page"
import Section from "../components/layout/Section"
import Displaier from "../components/content/Displaier"
import Heading from "../components/content/Heading"
import Media from "../components/content/Media"
import Action from "../components/element/Action"
import Menu from "../components/layout/Menu"

const Components = {
  page: Page,
  section: Section,
  displaier: Displaier,
  heading: Heading,
  media: Media,
  action: Action,
  menu: Menu,
}

const ComponentNotFound = ({ componentName }) => (
  <div className="component-not-found">
    The component {componentName} has not been created yet.
  </div>
)

const DynamicComponent = ({ blok, parent }) => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component]
    return <Component blok={blok} key={blok._uid} parent={parent} />
  }
  return blok.component ? (
    <ComponentNotFound componentName={blok.component} />
  ) : null
}

export default DynamicComponent
