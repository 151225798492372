import React from "react"
import PropTypes from "prop-types"
import { createUseStyles, JssProvider, createGenerateId } from "react-jss"
import { iconSchema } from "../../utils/componentSchemas"

const generateId = createGenerateId()

const Icon = props => {
  const classes = IconStyles({ ...props })
  return !!props.name ? (
    <JssProvider generateId={generateId}>
      <i className={`${classes.icon} ${classes.style}`} />
    </JssProvider>
  ) : null
}

Icon.defaultProps = {
  style: "default",
  name: null,
  size: "default",
}

Icon.prototype = {
  color: PropTypes.oneOf(iconSchema.styles),
  name: PropTypes.oneOf(iconSchema.names),
  size: PropTypes.oneOf(iconSchema.sizes),
}

const IconStyles = createUseStyles(({ icon }) => ({
  style: ({ name, color, size }) => ({
    color: icon.styles[color] || "inherit",
    fontSize: icon.sizes[size] || icon.size.default,
    "&::before": {
      content: icon.names[name] || null,
    },
  }),
  icon: {
    fontFamily: icon.font_family || "",
    fontStyle: "normal",
    fontWeight: "normal",
    fontVariant: "normal",
    textTransform: "none",
    lineHeight: "1",
    "-webkit-font-smoothing": "antialiased",
    "-moz-osx-font-smoothing": "grayscale",
  },
}))

export default Icon
