// Global variables
const breakpointVariables = {
  mobile: 428,
  tablet: 1024,
  laptop: 1366,
  desktop: 1920,
}
const mediaQueryVariables = {
  only: {
    mobile: `@media screen and (max-width: ${breakpointVariables.mobile}px)`,
    tablet: `@media screen  
      and (min-width: ${breakpointVariables.mobile + 1}px)
      and (max-width: ${breakpointVariables.tablet}px)`,
    laptop: `@media screen
      and (min-width: ${breakpointVariables.teblet + 1}px)
      and (max-width: ${breakpointVariables.laptop}px)`,
    desktop: `@media screen 
      and (min-width: ${breakpointVariables.laptop + 1}px)
      and (max-width: ${breakpointVariables.desktop}px)`,
  },
  until: {
    mobile: `@media screen and (max-width: ${breakpointVariables.mobile}px)`,
    tablet: `@media screen and (max-width: ${breakpointVariables.tablet}px)`,
    laptop: `@media screen and (max-width: ${breakpointVariables.laptop}px)`,
    desktop: `@media screen and (max-width: ${breakpointVariables.desktop})`,
  },
  from: {
    tablet: `@media screen and (min-width: ${
      breakpointVariables.mobile + 1
    }px)`,
    laptop: `@media screen and (min-width: ${
      breakpointVariables.tablet + 1
    }px)`,
    desktop: `@media screen and (min-width: ${
      breakpointVariables.laptop + 1
    }px)`,
    widescreen: `@media screen and (min-width: ${
      breakpointVariables.desktop + 1
    }px)`,
  },
}
const unitVariables = {
  mobile: "16px",
  tablet: "17px",
  laptop: "18px",
  desktop: "19px",
  widescreen: "20px",
}
const colorVariables = {
  primary_darken: "#332428",
  primary_dark: "#AE3241",
  primary: "#D33B44",
  primary_light: "#E3959A",
  primary_lighten: "#F5EFF1",

  secondary_darken: "#222C2F",
  secondary_dark: "#3F808D",
  secondary: "#52B0B6",
  secondary_light: "#98D1D4",
  secondary_lighten: "#F6F9F9",

  white: "#fff",
  black: "#000",

  gray_darken: "#343434",
  gray_dark: "#525252",
  gray_middle_dark: "#6F6F6F",
  gray_middle: "#9A9A9A",
  gray_middle_light: "#F8F8F9",
  gray_light: "#E5E5E5",
  gray_lighten: "#F5F5F5",

  error_light: "#F0C4C1",
  error: "#F36259",
  error_dark: "#F36259",

  success_light: "#B7E0C3",
  success: "#63CE81",
  success_dark: "#3EBA61",

  alert_light: "#FCE6BB",
  alert: "#F7C35E",
  alert_dark: "#F1B43D",

  info_light: "#C6E0EE",
  info: "#73BAE2",
  info_dark: "#539EC9",
}
const typografyVariables = {
  font_family: "'Open Sans', -apple-system, san-serif",
  default: {
    font_size: "1rem",
    font_weight: "400",
    line_height: "normal",
    letter_spacing: "normal",
    margin: "0",
  },
  // h1
  title: {
    font_size: ["4rem", "3rem"],
    font_weight: "800",
    line_height: "1.1",
    letter_spacing: "-.05rem",
    margin: ".75rem",
  },
  // h2
  subtitle: {
    font_size: ["2rem", "1.5rem"],
    font_weight: "700",
    line_height: "1",
    letter_spacing: "normal",
    margin: "1.5rem",
  },
  // h3
  heading: {
    font_size: ["2.5rem", "2rem"],
    font_weight: "800",
    line_height: "1.1",
    letter_spacing: "-.05rem",
    margin: ".75rem",
  },
  // h4
  subheading: {
    font_size: ["1.75rem", "1.25rem"],
    font_weight: "700",
    line_height: "1",
    letter_spacing: "normal",
    margin: "1.125rem",
  },
  // h5
  notehead: {
    font_size: "1.125rem",
    font_weight: "600",
    line_height: "1",
    letter_spacing: "normal",
    margin: ".875rem",
  },
  // q
  praise: {
    font_size: ["1.5rem", "1.125rem"],
    font_weight: "500",
    line_height: "",
    letter_spacing: "normal",
    margin: "1.125rem",
  },
  // p
  paragraph: {
    font_size: "1rem",
    font_weight: "400",
    line_height: "normal",
    letter_spacing: "normal",
    margin: ".875rem",
  },
  // 
  caption: {
    font_size: ".875rem",
    font_weight: "400",
    line_height: "1.1",
    letter_spacing: "normal",
    margin: ".5rem",
  },
  // small
  message: {
    font_size: ".75rem",
    font_weight: "400",
    line_height: "1.1",
    letter_spacing: "normal",
    margin: ".25rem",
  },
}
const indexVariables = {
  header: "50",
}
const columnsVariables = {
  gaps: {
    none: "0",
    small: ".75rem",
    default: "1rem",
    medium: "1.25rem",
    large: "1.5rem",
  },
}

export {
  breakpointVariables,
  mediaQueryVariables,
  unitVariables,
  colorVariables,
  typografyVariables,
  indexVariables,
  columnsVariables,
}
