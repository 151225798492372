import React from "react"
import PropTypes from "prop-types"
import SbEditable from "storyblok-react"
import { mediaSchema } from "../../utils/componentSchemas"
import { createUseStyles, JssProvider, createGenerateId } from "react-jss"
import { getWidth, getFlexPosition } from "../../utils/styleHelpers"
import placeholder from "../../images/placeholder.png"

const generateId = createGenerateId()
function Media(props) {
  const classes = MediaStyles({ ...props.blok })
  const { link } = props.blok

  return (
    <SbEditable content={props.blok} key={props.blok._uid}>
      <JssProvider generateId={generateId}>
        <div className={classes.media}>
          <a
            className={classes.mediaWrapper}
            href={!!link.cached_url ? link.cached_url : "javascript:void(0)"}
            target="_self"
          >
            {!props.blok.background && (
              <img
                className={classes.mediaContent}
                src={props.blok.image.filename}
                alt={props.blok.image.alt}
              />
            )}
          </a>
        </div>
      </JssProvider>
    </SbEditable>
  )
}
Media.defaultProps = {
  blok: {
    image: {
      filename: placeholder,
    },
    link: {
      cached_url: null,
    },
    size: 0,
    spacing: "default",
    alignment: "center",
    background: false,
    fillContainer: false,
  },
}

Media.prototype = {
  blok: PropTypes.shape({
    image: PropTypes.shape({
      filename: PropTypes.string,
      alt: PropTypes.string,
    }),
    link: PropTypes.shape({
      cached_url: PropTypes.string,
    }),
    size: PropTypes.oneOf(mediaSchema.sizes),
    spacing: PropTypes.oneOf(mediaSchema.spacings),
    alignment: PropTypes.oneOf(mediaSchema.image_alignments),
    background: PropTypes.bool,
    fillContainer: PropTypes.bool,
  }),
}

const MediaStyles = createUseStyles(({ column, mediaQuery, media }) => ({
  media: {
    flex: ({ size }) => (size > 0 ? "0 .5 auto" : "1 1 240px"),
    width: ({ size, parentGap, length }) =>
      size > 0 ? getWidth(column.gaps[parentGap] || "0", length, size) : "auto",
    textAlign: "center",
    display: "flex",
    alignSelf: "stretch",
    minHeight: ({ fillContainer }) => (!!fillContainer ? "inherit" : "auto"),
  },
  [mediaQuery.only.mobile]: {
    media: {
      flex: [["1 1 0"], "!important"],
      width: [["100%"], "!important"],
      order: [["1"], "!important"],
    },
  },
  mediaWrapper: ({ spacing, background, image, alignment, link }) => ({
    padding: media.spacings[spacing],
    cursor: !!link.cached_url ? "pointer" : "auto",
    width: "100%",
    minHeight: "240px",
    display: "flex",
    backgroundImage: background ? `url(${image.filename})` : null,
    backgroundPosition: background ? alignment : null,
    backgroundSize: background ? "cover" : null,
    backgroundRepeat: background ? "no-repeat" : null,
    alignItems: getFlexPosition(alignment, "align"),
    justifyContent: getFlexPosition(alignment, "justify"),
  }),
  mediaContent: {
    maxHeight: "320px",
    maxWidth: "100%",
    [mediaQuery.from.tablet]: {
      maxHeight: "440px",
    },
    [mediaQuery.from.laptop]: {
      maxHeight: "560px",
    },
    [mediaQuery.from.desktop]: {
      maxHeight: "640px",
    },
  },
}))

export default Media
