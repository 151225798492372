import React from "react"
import PropTypes from "prop-types"
import SbEditable from "storyblok-react"
import DynamicComponent from "../../utils/dynamicComponent"
import { createUseStyles } from "react-jss"
import { menuSchema } from "../../utils/componentSchemas"
import { getWidth } from "../../utils/styleHelpers"

function Menu({ blok }) {
  const classes = MenuStyles({ ...blok })
  const links =
    blok.links &&
    blok.links.map(childBlok => {
      childBlok.style = blok.style
      childBlok.size = !!blok.size ? blok.size : childBlok.size
      return <DynamicComponent blok={childBlok} key={childBlok._uid} />
    })
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className={classes.menu}>
        <h5 className={classes.menuTitle}>{blok.title}</h5>
        <nav className={classes.menuList}>{links}</nav>
      </div>
    </SbEditable>
  )
}

Menu.prototype = {
  blok: PropTypes.shape({
    title: PropTypes.string,
    links: PropTypes.array,
    style: PropTypes.oneOf(menuSchema.styles),
  }),
}

const MenuStyles = createUseStyles(({ menu }) => ({
  menu: ({ length, style }) => ({
    flex: !!length ? "0 0 auto" : "1 1 0",
    width: !!length ? getWidth("1rem", length, 4) : "auto",
    color: menu.styles[style].color,
    backgroundColor: menu.styles[style].background,
  }),
  menuTitle: {
    color: "inherit",
    "&::first-letter": {
      textTransform: "uppercase",
    },
  },
  menuList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    "& a": {
      color: "inherit",
      opacity: ".8",
      "&:hover": {
        opacity: "1",
        color: "inherit",
      },
    },
    "& button": {
      color: "inherit",
      opacity: ".8",
      "&:hover": {
        opacity: "1",
        color: "inherit",
      },
    },
  },
}))

export default Menu
