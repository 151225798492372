import React from "react"
import PropTypes from "prop-types"
import SbEditable from "storyblok-react"
import DynamicComponent from "../../utils/dynamicComponent"
import StoryblokClient from "storyblok-js-client"
import { createUseStyles, JssProvider, createGenerateId } from "react-jss"
import { headingSchema } from "../../utils/componentSchemas"
import { getWidth, getFlexPosition } from "../../utils/styleHelpers"

const sbClient = new StoryblokClient({
  accessToken: `${process.env.SB_TOKEN}`,
})

const generateId = createGenerateId()

function Heading(props) {
  const classes = HeadingStyles({ ...props.blok })
  const content = sbClient.richTextResolver.render(props.blok.content)

  const actions =
    props.blok.actions &&
    props.blok.actions.map(childBlok => (
      <DynamicComponent blok={childBlok} key={childBlok._uid} />
    ))

  return (
    <SbEditable content={props.blok} key={props.blok._uid}>
      <JssProvider generateId={generateId}>
        <div className={classes.heading}>
          <div
            className={classes.headingContent}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {!!props.blok.actions.length && (
            <div className={classes.headingActions}>{actions}</div>
          )}
        </div>
      </JssProvider>
    </SbEditable>
  )
}

Heading.defaultProps = {
  content: {},
  actions: [],
  style: "default",
  size: "auto",
  spacing: "defalut",
  align: "left",
  gap: "default",
  fillContainer: false,
}

Heading.prototype = {
  blok: PropTypes.shape({
    content: PropTypes.object,
    actions: PropTypes.array,
    style: PropTypes.oneOf(headingSchema.styles),
    size: PropTypes.oneOf(headingSchema.sizes),
    gap: PropTypes.oneOf(headingSchema.gaps),
    spacing: PropTypes.oneOf(headingSchema.spacings),
    align: PropTypes.oneOf(headingSchema.text_alignment),
    fillContainer: PropTypes.bool,
  }),
}

const HeadingStyles = createUseStyles(({ column, heading, mediaQuery }) => ({
  heading: {
    flex: ({ size }) => (size > 0 ? "0 .5 auto" : "1 1 240px"),
    width: ({ size, parentGap, length }) =>
      size > 0 ? getWidth(column.gaps[parentGap], length, size) : "auto",
    textAlign: ({ align }) => align,
    display: "flex",
    flexDirection: "column",
    padding: ({ spacing }) =>
      `${spacing === "none" ? "0" : heading.spacings[spacing]} 0`,
    rowGap: ({ gap }) => column.gaps[gap],
    minHeight: ({ fillContainer }) => (!!fillContainer ? "inherit" : "auto"),
  },
  [mediaQuery.only.mobile]: {
    heading: {
      flex: [["1 1 0"], "!important"],
      width: [["100%"], "!important"],
      order: [["2"], "!important"],
      justifyContent: "space-between",
    },
  },
  headingContent: {
    // backdropFilter: "blur(4px)",
    color: ({ style }) => heading.styles[style].default,
    "& h1": {
      color: ({ style }) => heading.styles[style].title,
    },
    "& h2": {
      color: ({ style }) => heading.styles[style].subtitle,
    },
    "& h3": {
      color: ({ style }) => heading.styles[style].heading,
    },
    "& h4": {
      color: ({ style }) => heading.styles[style].subheading,
    },
    "& h5": {
      color: ({ style }) => heading.styles[style].praise,
    },
    "& p": {
      color: ({ style }) => heading.styles[style].paragraph,
    },
    "& .hiddenMobile": {
      [mediaQuery.only.mobile]: {
        display: "none",
      },
    },
    "& .hiddenUntilTablet": {
      [mediaQuery.until_laptop]: {
        display: "none",
      },
    },
    "& .hiddenUntilLaptop": {
      [mediaQuery.until_desktop]: {
        display: "none",
      },
    },
  },
  headingActions: {
    display: "inline-flex",
    alignItems: "centerow",
    columnGap: ".75rem",
    marginTop: "1rem",
    justifyContent: ({ align }) => getFlexPosition(align, "justify"),
    [mediaQuery.only.mobile]: {
      justifyContent: "space-around",
    },
  },
}))

export default Heading
