import React from "react"
import { Helmet } from "react-helmet"

import Header from "./layout/Header"
import Footer from "./layout/Footer"

export default function Layout(props) {
  const settings = props.settings
  const header = !!settings.header && JSON.parse(settings.header.content)
  const footer = !!settings.footer && JSON.parse(settings.footer.content)
  return (
    <>
      <Helmet title="Website template" defer={false}>
        Seo
      </Helmet>
      <Header blok={header} />
      <main>{props.children}</main>
      <Footer blok={footer} />
    </>
  )
}
