import React from "react"
import PropTypes from "prop-types"
import { createUseStyles, JssProvider, createGenerateId } from "react-jss"
import SbEditable from "storyblok-react"
import { actionSchema } from "../../utils/componentSchemas"
import Icon from "./Icon"

const generateId = createGenerateId()
function Action({ blok }) {
  const classes = ActionStyles({ ...blok })

  function fireAction(fn) {
    switch (fn) {
      case "logger":
        console.log("Button logger function")
        break
      default:
        break
    }
  }

  return (
    <SbEditable content={blok} key={blok._uid}>
      {!!blok.link.cached_url ? (
        <JssProvider generateId={generateId}>
          <a
            id={blok.id}
            className={`${classes.actionLink} ${classes[blok.type]}`}
            href={blok.link.cached_url}
            target={blok.target}
          >
            <Icon name={blok.icon_left} size={blok.size} />
            {blok.text}
            <Icon name={blok.icon_right} size={blok.size} />
          </a>
        </JssProvider>
      ) : (
        <JssProvider generateId={generateId}>
          <button
            id={blok.id}
            className={`${classes.actionButton} ${classes[blok.type]}`}
            onClick={() => fireAction(blok.fn)}
          >
            <Icon name={blok.icon_left} size={blok.size} />
            {blok.text}
            <Icon name={blok.icon_right} size={blok.size} />
          </button>
        </JssProvider>
      )}
    </SbEditable>
  )
}

Action.defaultProps = {
  text: "action",
  type: "text",
  style: "default",
}

Action.prototype = {
  blok: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.shape({
      cached_url: PropTypes.string,
    }),
    target: PropTypes.oneOf(actionSchema.targets),
    function: PropTypes.oneOf(["logger"]),
    type: PropTypes.oneOf(actionSchema.types),
    style: PropTypes.oneOf(actionSchema.styles),
    size: PropTypes.oneOf(actionSchema.sizes),
    icon: PropTypes.shape({
      icon_left: PropTypes.oneOf(actionSchema.icons),
      icon_right: PropTypes.oneOf(actionSchema.icons),
    }),
  }),
}

const ActionStyles = createUseStyles(({ action }) => ({
  action: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-around",
    transition: "all .32s ease-out",
    cursor: "pointer",
  },
  actionLink: {
    extend: "action",
  },
  actionButton: {
    extend: "action",
    outline: "none",
    border: "none",
  },
  button: {
    color: ({ style }) => action.styles[style].button_color,
    backgroundColor: ({ style }) => action.styles[style].button_background,
    borderRadius: ({ size }) => action.sizes[size].button_radius,
    columnGap: ({ size }) => action.sizes[size].button_gap,
    fontSize: ({ size }) => action.sizes[size].button_font_size,
    fontWeight: ({ size }) => action.sizes[size].button_font_weight,
    padding: ({ size }) => action.sizes[size].button_padding,
    "&:hover": {
      transition: "all .23s ease-in",
      backgroundColor: ({ style }) =>
        action.styles[style].button_background_hover,
    },
  },
  text: {
    color: ({ style }) => action.styles[style].text_color,
    columnGap: ({ size }) => action.sizes[size].text_gap,
    fontSize: ({ size }) => action.sizes[size].text_font_size,
    fontWeight: ({ size }) => action.sizes[size].text_font_weight,
    "&:hover": {
      transition: "all .23s ease-in",
      color: ({ style }) => action.styles[style].text_color_hover,
    },
  },
}))

export default Action
