import { displaierVariables, headingVariables, mediaVariables } from "./content"
import { actionVariables, iconVariables } from "./element"
import {
  breakpointVariables,
  mediaQueryVariables,
  unitVariables,
  colorVariables,
  typografyVariables,
  indexVariables,
  columnsVariables,
} from "./global"
import {
  sectionVariables,
  containerVariables,
  headerVariables,
  footerVariables,
  menuVariables,
} from "./layout"

// All theme variables
const theme = {
  default_text_color: colorVariables.gray_darken,
  default_background_color: colorVariables.white,

  breakpoint: breakpointVariables,
  mediaQuery: mediaQueryVariables,
  unit: unitVariables,
  color: colorVariables,
  typografy: typografyVariables,
  index: indexVariables,
  column: columnsVariables,

  action: actionVariables,
  icon: iconVariables,

  displaier: displaierVariables,
  heading: headingVariables,
  media: mediaVariables,

  header: headerVariables,
  section: sectionVariables,
  container: containerVariables,
  footer: footerVariables,
  menu: menuVariables,
}
export default theme
