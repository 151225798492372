import { colorVariables } from "./global"
// Content component variables
const displaierVariables = {
  spacings: {
    none: "0",
    small: ".75rem",
    default: "1.5rem",
    medium: "3rem",
    large: "5rem",
  },
}
const headingVariables = {
  styles: {
    dark: {
      default: colorVariables.gray_middle,
      title: colorVariables.gray_darken,
      subtitle: colorVariables.gray_middle_dark,
      heading: colorVariables.gray_dark,
      subheading: colorVariables.gray_middle_dark,
      paragraph: colorVariables.gray_middle,
      caption: colorVariables.gray_middle_light,
      praise: colorVariables.gray_middle_dark,
    },
    light: {
      default: colorVariables.gray_middle_light,
      title: colorVariables.white,
      subtitle: colorVariables.gray_light,
      heading: colorVariables.gray_lighten,
      subheading: colorVariables.gray_middle_light,
      paragraph: colorVariables.gray_middle_light,
      caption: colorVariables.gray_middle_dark,
      praise: colorVariables.gray_middle_light,
    },
    primary: {
      default: colorVariables.gray_middle,
      title: colorVariables.primary,
      subtitle: colorVariables.primary_light,
      heading: colorVariables.primary_dark,
      subheading: colorVariables.gray_middle_dark,
      paragraph: colorVariables.gray_middle,
      caption: colorVariables.gray_middle_light,
      praise: colorVariables.primary_light,
    },
    secondary: {
      default: colorVariables.gray_middle,
      title: colorVariables.secondary,
      subtitle: colorVariables.secondary_light,
      heading: colorVariables.secondary_dark,
      subheading: colorVariables.gray_middle_dark,
      paragraph: colorVariables.gray_middle,
      caption: colorVariables.gray_middle_light,
      praise: colorVariables.secondary_light,
    },
  },
  spacings: {
    none: "0",
    small: ".25rem",
    default: ".75rem",
    medium: "1.25rem",
    large: "2rem",
  },
}
const mediaVariables = {
  spacings: {
    none: "0",
    small: ".25rem",
    default: ".75rem",
    medium: "1.25rem",
    large: "2rem",
  },
}

export { displaierVariables, headingVariables, mediaVariables }
