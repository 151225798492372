import React from "react"
import DynamicComponent from "../utils/dynamicComponent"
import { createUseStyles } from "react-jss"

export default function Page({ blok }) {
  GeneralStyles()
  const content =
    blok.body &&
    blok.body.map(childBlok => (
      <DynamicComponent blok={childBlok} key={childBlok._uid} />
    ))
  return content
}

const GeneralStyles = createUseStyles(
  ({
    mediaQuery,
    typografy,
    unit,
    default_background_color,
    default_text_color,
    color,
    header,
  }) => {
    return {
      "@global": {
        html: {
          fontFamily: typografy.font_family,
          fontSize: unit.mobile,
          backgroundColor: default_background_color,
          color: default_text_color,

          [mediaQuery.from.tablet]: {
            fontSize: unit.tablet,
          },
          [mediaQuery.from.laptop]: {
            fontSize: unit.laptop,
          },
          [mediaQuery.from.desktop]: {
            fontSize: unit.desktop,
          },
          [mediaQuery.from.widescreen]: {
            fontSize: unit.widescreen,
          },
        },
        body: {
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
          textRendering: "optimizeLegibility",
          "&::-webkit-scrollbar": {
            width: 2,
            backgroundColor: color.gray_darken,
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 1px rgba(0, 0, 0, 0.1)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: color.gray_dark,
          },
          "scrollbar-color": `${color.gray_dark} ${color.gray_darken}`,
          "scrollbar-width": "thin",
        },
        a: {
          textDecoration: "none",
        },
        button: {
          border: "none",
          outline: "none",
          fontFamily: "inherit",
          backgroundColor: "transparent",
        },
        input: {
          outline: "none",
          border: "none",
        },
        main: {
          minHeight: `calc(100vh - ${header.heights.small})`,
          [mediaQuery.from.tablet]: {
            minHeight: `calc(100vh - ${header.heights.default})`,
          },
          [mediaQuery.from.desktop]: {
            minHeight: `calc(100vh - ${header.heights.medium})`,
          },
          [mediaQuery.from.widescreen]: {
            minHeight: `calc(100vh - ${header.heights.large})`,
          },
        },
        "h1, h2, h3, h4, h5, h6, p": {
          margin: "0",
        },
        h1: {
          fontWeight: typografy.title.font_weight,
          lineHeight: typografy.title.line_height,
          letterSpacing: typografy.title.letter_spacing,
          marginBottom: typografy.title.margin,
          fontSize: typografy.title.font_size[0],
          [mediaQuery.until.tablet]: {
            fontSize: typografy.title.font_size[1],
          },
        },
        h2: {
          fontWeight: typografy.subtitle.font_weight,
          lineHeight: typografy.subtitle.line_height,
          letterSpacing: typografy.subtitle.letter_spacing,
          marginBottom: typografy.subtitle.margin,
          fontSize: typografy.subtitle.font_size[0],
          [mediaQuery.until.tablet]: {
            fontSize: typografy.subtitle.font_size[1],
          },
        },
        h3: {
          fontWeight: typografy.heading.font_weight,
          lineHeight: typografy.heading.line_height,
          letterSpacing: typografy.heading.letter_spacing,
          marginBottom: typografy.heading.margin,
          fontSize: typografy.heading.font_size[0],
          [mediaQuery.until.tablet]: {
            fontSize: typografy.heading.font_size[1],
          },
        },
        h4: {
          fontWeight: typografy.subheading.font_weight,
          lineHeight: typografy.subheading.line_height,
          letterSpacing: typografy.subheading.letter_spacing,
          marginBottom: typografy.subheading.margin,
          fontSize: typografy.subheading.font_size[0],
          [mediaQuery.until.tablet]: {
            fontSize: typografy.subheading.font_size[1],
          },
        },
        h5: {
          fontWeight: typografy.notehead.font_weight,
          lineHeight: typografy.notehead.line_height,
          letterSpacing: typografy.notehead.letter_spacing,
          marginBottom: typografy.notehead.margin,
          fontSize: typografy.notehead.font_size,
        },
        h6: {
          fontWeight: "600",
          lineHeight: "1",
          letterSpacing: "normal",
          marginBottom: ".5rem",
          fontSize: ".875rem",
        },
        q: {
          fontWeight: typografy.praise.font_weight,
          lineHeight: typografy.praise.line_height,
          letterSpacing: typografy.praise.letter_spacing,
          marginBottom: typografy.praise.margin,
          fontSize: typografy.praise.font_size[0],
          [mediaQuery.until.tablet]: {
            fontSize: typografy.praise.font_size[1],
          },
        },
        small: {
          fontWeight: typografy.message.font_weight,
          lineHeight: typografy.message.line_height,
          letterSpacing: typografy.message.letter_spacing,
          marginBottom: typografy.message.margin,
          fontSize: typografy.message.font_size,
        },
      },
    }
  }
)
